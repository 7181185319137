@import '../../../stylesheets/variables.scss';

#nomatch {
  position: relative;
  background: $darkBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;

  h1 {
    margin: 0px;
    font-size: 30px;
    font-family: $fontBarlowSemiCondensed;
    font-weight: 500;
    color: $white;
  }

  h4 {
    text-align: center;
    margin: 20px 0px 0px;
    font-size: 15px;
    font-weight: 400;
    color: $white;

    a {
      margin: 0px;
      font-size: 15px;
      font-weight: 400;
      color: $white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}