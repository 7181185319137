html,
body {
  position: relative;
  color: $black;
  margin: 0px;
  padding: 0px;
  font-family: $fontLato;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  background: $darkBlue;
}

* {
  outline: none !important;
  box-sizing: border-box;
}

.section-header-text {
  h1 {
    font-style: normal;
    font-family: $fontBarlowSemiCondensed;
    font-weight: 500;
    font-size: 57.7037px;
    line-height: 100%;
    color: $blue;
    margin-top: 0px;
    margin-bottom: 32px;

    .highlight {
      font-style: normal;
      font-family: $fontBarlowSemiCondensed;
      font-weight: 500;
      font-size: 57.7037px;
      line-height: 100%;
      color: $orange;
    }
  }

  p {
    font-size: 21px;
    line-height: 130%;
    margin: 0px;
    max-width: 1127px;
  }
}

.ja {
  .section-header-text {
    p {
      font-size: 19px;
      line-height: 144%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .section-header-text {
    h1 {
      font-size: 36px;
      line-height: 110%;
      margin-bottom: 12px;

      .highlight {
        font-size: 36px;
        line-height: 110%;
      }
    }
  }

  .ja {
    .section-header-text {
      h1 {
        font-size: 40px;
        line-height: 100%;

        .highlight {
          font-size: 40px;
          line-height: 100%;
        }
      }

      p {
        font-size: 16px;
        line-height: 150%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .section-header-text {
    p {
      font-size: 16px;
      line-height: 140%;
    }
  }

  .ja {
    .section-header-text {
      h1 {
        font-size: 33px;
        line-height: 100%;

        .highlight {
          font-size: 33px;
          line-height: 100%;
        }
      }

      p {
        font-size: 16px;
        line-height: 150%;
      }
    }
  }
}