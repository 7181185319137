$black: rgba(51, 51, 51, 0.87);
$white: #FFFFFF;
$gray: #F4F5F7;
$blue: #003064;
$orange: #E84228;
$lightBlue: #aabdd8;
$darkBlue: #1C2A39;

$font-url: '../assets/fonts/';
$fontLato: 'Lato',
sans-serif;
$fontBarlowCondensed: 'Barlow Condensed',
sans-serif;
$fontBarlowSemiCondensed: 'Barlow Semi Condensed',
sans-serif;
$fontOpenSans: 'Open Sans',
sans-serif;
$fontNatoSansJP: 'Noto Sans JP',
sans-serif;